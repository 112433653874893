// See https://www.gatsbyjs.org/packages/gatsby-transformer-remark/

import React from "react"
import styled from "styled-components"
import { IntlContextConsumer, useIntl } from "gatsby-plugin-intl"
import { graphql } from "gatsby"

import Blogging from "../assets/Blogging.svg"

import Footer from "../components/Footer"
import Layout from "../components/Layout"
import Meta from "../components/Meta"
import Navbar from "../components/Navbar"
import BlogPostItem from "../components/organisms/BlogPostItem"
import { H1 } from "../components/atoms/ResTypography"
import { PageProps } from "../helpers/props"

const SectionBlog = styled.section`
  .il {
    width: 80%;
  }
`

interface BlogPageProps extends PageProps {
  data: any
}

export const latestBlogPostsQuery = graphql`
  query LatestBlogPosts {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }, limit: 25) {
      edges {
        node {
          excerpt(pruneLength: 200)
          frontmatter {
            author
            date
            lang
            path
            title
          }
        }
      }
    }
  }
`

const BlogPage: React.FunctionComponent<BlogPageProps> = ({ data, location }) => {
  const intl = useIntl()
  const title = intl.formatMessage({ id: "Blog" })

  return (
    <Layout>
      <Meta pathname={location.pathname} title={title} />
      <Navbar isFixedTop={false} />
      <SectionBlog className="section">
        <div className="container">
          <div className="columns">
            <div className="column">
              <H1 className="title">{title}</H1>
            </div>
          </div>
          <div className="columns">
            <div className="column is-two-thirds-tablet is-three-fifths-desktop">
              <IntlContextConsumer>
                {({ language }: { language: string }) =>
                  data.allMarkdownRemark.edges.reduce((acc: any[], item: any, index: number) => {
                    const post = {
                      ...item.node.frontmatter,
                      excerpt: item.node.excerpt,
                    }
                    if (post.lang === language) {
                      acc.push(<BlogPostItem key={index} post={post} />)
                    }
                    return acc
                  }, [])
                }
              </IntlContextConsumer>
            </div>
            <div className="column has-text-centered is-hidden-mobile">
              <img alt="" className="il" src={Blogging} />
            </div>
          </div>
        </div>
      </SectionBlog>
      <Footer />
    </Layout>
  )
}

export default BlogPage
