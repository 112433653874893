import { FormattedMessage } from "gatsby-plugin-intl"
import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import styled from "styled-components"

import { H2 } from "../atoms/ResTypography"

export interface BlogPost {
  author: string
  cover: string
  date: string
  excerpt: string
  lang: string
  path: string
  title: string
  tname?: string
}

interface BlogPostItemProps {
  key: number
  post: BlogPost
}

const StyledArticle = styled.article`
  margin: 0 0 1.75rem 0;

  .post-header {
    margin: 0 0 1rem 0;

    .post-title {
      margin: 0 0 0.25rem 0;
    }
  }
  .post-content {
    margin: 0 0 1rem 0;
  }
`

const BlogPostItem: React.FunctionComponent<BlogPostItemProps> = ({ post }) => {
  const fullPostUri = post.path.replace("blog/", "")
  const intl = useIntl()
  let blogPostAuthor = intl.formatMessage({ id: "blog_post_author_template" }, { author: post.author, date: post.date }) as string
  blogPostAuthor = blogPostAuthor?.replace("{author}", post.author)
  blogPostAuthor = blogPostAuthor?.replace("{date}", post.date)
  return (
    <StyledArticle>
      <header className="post-header">
        <a href={fullPostUri}>
          <H2 className="post-title">{post.title}</H2>
        </a>
      </header>
      <section className="post-content">
        <p>{post.excerpt}</p>
      </section>
      <footer>
        <span>{blogPostAuthor}</span>
        &nbsp;·&nbsp;
        <a href={fullPostUri}>
          <FormattedMessage id="blog_post_read_more" />
        </a>
      </footer>
    </StyledArticle>
  )
}

export default BlogPostItem
